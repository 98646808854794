.quality
  @media screen and (min-width: 768px) and (max-width: 1279px)
    margin-bottom: 116px
  &__wrap
    display: flex
    justify-content: flex-start
    align-items: flex-start
    @media screen and (max-width: 1279px)
      flex-direction: column
  &__img
    width: 100%
    max-width: 234px
    object-fit: cover
    position: relative
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      max-width: 184px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      max-width: 170px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      max-width: 100%
      height: 257px
      margin: 0 0 32px
      img
        position: absolute
        width: 100%
        height: auto
        left: 0
        right: 0
        bottom: -50%
    @media screen and (max-width: 767px)
      max-width: 100%
      margin: 0 0 32px
      height: 170px
      img
        position: absolute
        width: 100%
        height: auto
        left: 0
        right: 0
        bottom: 10%
  &__info
    margin-left: 165px
    width: 766px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 616px
      margin-left: 140px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 584px
      margin-left: 133px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      width: 568px
      margin: 0
    @media screen and (max-width: 1279px)
      width: 100%
      margin: 0
  &__title
    max-width: 584px
  &__text
    font: normal normal normal 16px/28px Inter
    display: grid
    grid-gap: 32px
    color: #414141
    @media screen and (min-width: 1660px)
      grid-template-columns: 1fr 1fr
    @media screen and (max-width: 1279px)
      font-size: 14px
      line-height: 24px
      grid-template-columns: 1fr