.map
  position: relative
  @media screen and (min-width: 1920px)
    height: 1080px
  @media screen and (min-width: 1660px) and (max-width: 1919px)
    height: 898px
  @media screen and (min-width: 1366px) and (max-width: 1659px)
    height: 768px
  @media screen and (min-width: 1280px) and (max-width: 1365px)
    height: 800px
  @media screen and (max-width: 767px)
    height: unset
  &__wrap
    width: 100%
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    @media screen and (max-width: 1279px)
      position: unset
      height: 100%
  &__box
    position: relative
    top: 100px
    left: 0
    width: 431px
    z-index: 1
    background-color: $colorSecond
    padding: 36px 32px
    color: #fff
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 342px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 335px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      width: calc(100% + 80px)
      position: relative
      left: -40px
      top: 0
      padding: 36px 72px 71px
    @media screen and (max-width: 767px)
      width: calc(100% + 32px)
      position: relative
      left: -16px
      top: 0
    &-title
      color: #fff
    a
      color: #fff
      font: normal normal normal 18px/28px Inter
      @media screen and (min-width: 1660px)
        margin-right: 21px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        display: block
        margin-bottom: 21px
      @media screen and (max-width: 767px)
        margin-bottom: 21px
        &:last-of-type
          margin-bottom: 0
    address
      margin-top: 32px
      font: normal normal normal 18px/28px Inter
      span
        white-space: nowrap
      @media screen and (min-width: 768px) and (max-width: 1279px)
        grid-column: 2/3
        grid-row: 1/3
        margin-top: 0
    &-container
      @media screen and (min-width: 768px) and (max-width: 1279px)
        display: grid
        grid-template-columns: 1fr 1fr
        grid-gap: 21px 157px
      @media screen and (max-width: 767px)
        display: flex
        flex-direction: column