.contacts
  .container-outer
    position: unset
  .map
    @media screen and (min-width: 1660px)
      height: 980px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      height: 768px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      height: 800px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      height: 1024px
    @media screen and (max-width: 767px)
      height: 900px
    &__box
      top: 122px
      padding: 40px 32px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        width: 365px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        position: absolute
        top: unset
        bottom: 0
        left: 0
        right: 0
        width: 100%
        padding: 40px 40px 0
      @media screen and (max-width: 767px)
        position: absolute
        left: 0
        right: 0
        top: unset
        bottom: 0
        width: 100%
        padding: 36px 17px 0
      &-container
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start
        @media screen and (min-width: 768px) and (max-width: 1279px)
          border-bottom: 2px solid #008066
          display: grid
          grid-gap: 0 59px
          grid-template-columns: 1fr 284px
          padding: 0 0 51px
        @media screen and (max-width: 767px)
          border-bottom: 2px solid #008066
          display: grid
          grid-template-columns: 1fr
          padding: 0 0 51px
      a
        font-size: 28px
        margin-bottom: 24px
        &:last-of-type
          margin-bottom: 0
        @media screen and (min-width: 768px) and (max-width: 1279px)
          grid-column: 1/2
        @media screen and (max-width: 767px)
          font-size: 14px
          margin-bottom: 21px
      &-button
        font-size: 18px
        margin-top: 62px
        @media screen and (min-width: 768px) and (max-width: 1279px)
          margin-top: 0
          grid-column: 2/3
          grid-row: 1/2
        @media screen and (max-width: 767px)
          width: 100%
          justify-content: center
          margin-top: 45px
      address
        @media screen and (min-width: 768px) and (max-width: 1279px)
          margin-top: 32px
          grid-column: 1/2
          grid-row: 3/4
        @media screen and (max-width: 767px)
          font-size: 14px