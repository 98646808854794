.interior
  &__wrap
    display: grid
    grid-gap: 32px
    grid-template-columns: repeat(2, 500px)
    @media screen and (max-width: 1279px)
      grid-template-columns: 1fr
  &__item
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    align-items: flex-start
    font: normal normal normal 16px/28px Inter
    color: #414141
    @media screen and (max-width: 1279px)
      font-size: 14px
      line-height: 24px
    &:hover
      .interior__item-title:before
        width: 100px
      img
        transform: scale(1.2)
    &:first-of-type
      grid-column: 1/3
      @media screen and (max-width: 1279px)
        grid-column: unset
    &-img
      width: 500px
      height: 357px
      @media screen and (max-width: 1279px)
        width: 100%
        height: unset
        aspect-ratio: 326/257
    &-box
      padding: 32px
      max-width: 431px
      @media screen and (max-width: 1279px)
        padding: 32px 0 0 32px
    &-title
      display: block
      color: $colorGreen
      padding-bottom: 8px
      font-weight: 600
      font-size: 20px
      line-height: 24px
      position: relative
      &:before
        content: ""
        position: absolute
        height: 3px
        width: 0
        background-color: $colorGreen
        transition: width 0.3s ease-in-out
        left: 0
        top: -10px
.interior-detailed
  &__item
    display: flex
    align-items: flex-start
    justify-content: flex-start
    gap: 32px
    margin: 0 30px
    @media screen and (max-width: 1279px)
      flex-direction: column
  &__text
    font: normal normal normal 16px/28px Inter
    width: 234px
    max-height: 784px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 184px
      max-height: unset
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 170px
      max-height: unset
    @media screen and (max-width: 1279px)
      order: 1
      width: 100%
      font-size: 14px
      line-height: 24px
      display: flex
      align-items: flex-start
      justify-content: flex-start
      flex-direction: column-reverse
  &__year
    padding: 102px 0
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      padding: 70px 0
    @media screen and (max-width: 1279px)
      padding: 0 10px 21px 10px
      margin-left: auto
  &__img
    cursor: pointer
    @media screen and (max-width: 1279px)
      order: 0
      width: calc(100% + 32px)
      height: 257px
      position: relative
      left: -16px
    &:hover img
      transform: scale(1.2)
      @media screen and (max-width: 1279px)
        transform: unset
  &__slider-wrap
    position: relative
  &__arrow-box
    position: absolute
    bottom: 50px
    left: 0
    z-index: 1
    cursor: pointer
    display: flex
    align-items: center
    justify-content: space-between
    width: 186px
    font: normal normal normal 22px/28px Inter
    color: #414141
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 170px
    @media screen and (max-width: 1279px)
      left: 10px
      bottom: unset
      top: 282px
      .default-arrow
        display: none
  &__slider
    margin: 0 -30px