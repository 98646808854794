.overlay
  position: fixed
  top: 0
  bottom: -5vh
  left: 0
  right: 0
  z-index: 9
  background-color: rgba(24, 38, 56, 0.9)
  display: none
  overflow-y: auto
  &.active
    display: block
.modal
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%,-50%)
  display: none
  @media screen and (max-width: 767px)
    top: 0
    left: 0
    right: 0
    transform: unset
  &.active
    display: block
  &__close
    position: absolute
    right: 50px
    top: 50px
    cursor: pointer
    background-color: rgba(24, 38, 56, 1)
    display: flex
    align-items: center
    justify-content: center
    width: 49px
    height: 49px
    border-radius: 50%
    overflow: hidden
    z-index: 2
    &:before, &:after
      content: ""
      background-color: #fff
      display: block
      width: 25px
      height: 3px
      transform-origin: center center
      position: absolute
    &:before
      transform: rotate(45deg)
    &:after
      transform: rotate(-45deg)
    @media screen and (max-width: 767px)
      width: 32px
      height: 32px
      top: 17px
      right: 17px
  &__arrow
    cursor: pointer
    position: absolute
    z-index: 1
    width: 43px
    height: 43px
    top: calc(50% - 20px)
    &_left
      transform: rotate(135deg)
      left: 10%
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        left: 50%
        transform: translateX(-42vw) rotate(135deg)
    &_right
      transform: rotate(-45deg)
      right: 10%
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        right: 50%
        transform: translateX(42vw) rotate(-45deg)
    .arrow
      border: solid #008066
      border-width: 0 3px 3px 0
      padding: 20px
      display: block
      transition: border-color 0.3s ease
      &:hover
        border-color: $colorDarkGreen
  &-slider
    &__item
      max-width: 1043px
      margin: auto
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        //aspect-ratio: 1043/827
        max-height: 90vh
    &__arrow-box
      position: absolute
      bottom: 22px
      left: 50%
      transform: translateX(-50%)
      z-index: 3
      color: #fff
      font: normal normal normal 22px/1.4 Inter
      text-align: center
      padding: 6px 14px
      letter-spacing: 2.2px
      background-color: rgba(24, 38, 56, 1)
      border-radius: 90px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        padding: 5px 10px
        font-size: 18px
.modal-callback, .modal-header, .modal-msg
  background-color: $colorMain
  padding: 56px 133px 66px
  width: 100%
  max-width: 1065px
  @media screen and (min-width: 1280px) and (max-width: 1659px)
    padding: 40px 100px 50px
    max-width: 80vw
  @media screen and (min-width: 768px) and (max-width: 1279px)
    padding: 44px 32px
    max-width: calc(100% - 40px)
  @media screen and (max-width: 767px)
    padding: 89px 17px 57px
    max-width: 100%
  &__title
    color: rgba(47, 58, 65, 1)
    font-size: 48px
    line-height: 117%
    padding-bottom: 37px
    font-weight: 500
    @media screen and (max-width: 767px)
      font-size: 32px
      font-weight: 600
  &__desc
    max-width: 549px
    font-size: 28px
    line-height: 32px
    font-weight: 500
    padding-bottom: 59px
    @media screen and (max-width: 767px)
      font-size: 20px
      padding-bottom: 50px
  &__row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 32px
    padding-bottom: 44px
    &:last-of-type
      padding-bottom: 88px
    @media screen and (min-width: 768px) and (max-width: 1659px)
      padding-bottom: 40px
    @media screen and (max-width: 767px)
      flex-direction: column
      justify-content: flex-start
      align-items: flex-start
    &-submit
      display: flex
      align-items: center
      justify-content: space-between
      gap: 60px
      @media screen and (max-width: 1023px)
        gap: 40px
      @media screen and (max-width: 767px)
        flex-direction: column
        align-items: flex-start
  &__field
    width: 100%
    a
      color: $colorGreen
      text-decoration: underline
  &__checkbox
    position: relative
    font-size: 14px
    color: #898989
    width: 300px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    &.error
      width: unset
      input::before
        border-color: #f01e2c
    input
      width: 0
      appearance: none
      margin-right: 40px
      &::before
        content: ""
        display: block
        width: 22px
        height: 22px
        border: 1px solid $colorGreen
      &::after
        content: ''
        width: 13px
        height: 7px
        position: absolute
        top: 7px
        left: 8px
        border-bottom: 3px solid $colorGreen
        border-left: 3px solid $colorGreen
        transform: rotate(-45deg)
        display: none
      &:checked::after
        display: block
  &__label
    display: inline-block
    font: normal normal normal 14px/24px Inter
    color: rgba(65, 65, 65, 1)
    padding-bottom: 16px
  &__input
    border: 1px solid rgba(150, 150, 150, 1)
    height: 48px
    background-color: transparent
    width: 100%
    padding: 5px 10px
    @media screen and (max-width: 767px)
      height: 43px
  &__textarea
    border: 1px solid rgba(150, 150, 150, 1)
    height: 100px
    background-color: transparent
    width: 100%
    padding: 5px 10px
  &__submit
    padding: 20px 66px
    display: block
    margin-left: auto
    border: none
    @media screen and (max-width: 767px)
      margin: 0
      width: 100%
.modal-msg
  &__desc
    max-width: 759px
  &__check
    display: flex
    justify-content: space-between
    align-items: flex-start
    @media screen and (max-width: 767px)
      flex-direction: column
      justify-content: flex-start
      gap: 32px
    span
      font-family: Inter, sans-serif
      color: #898989
      max-width: 500px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        max-width: 467px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        max-width: 374px
      @media screen and (max-width: 767px)
        max-width: 100%
