.banner
  padding-bottom: 100px
  @media screen and (max-width: 1279px)
    padding-bottom: 87px
  &__wrap
    display: flex
    align-items: center
    justify-content: space-between
    @media screen and (max-width: 1279px)
      flex-direction: column
      justify-content: flex-start

  &__info
    max-width: 674px
    @media screen and (min-width: 1660px) and (max-width: 1919px)
      max-width: 641px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      max-width: 560px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 505px
    @media screen and (max-width: 1279px)
      width: 100%
      padding-bottom: 89px
  &__title
    color: $colorBlack
    font-size: 62px
    font-weight: 600
    line-height: 1.15
    margin-bottom: 50px
    @media screen and (max-width: 767px)
      font-size: 32px
      margin-bottom: 30px
  &__text
    font-family: Inter, sans-serif
    line-height: 1.75
    color: #414141
    @media screen and (min-width: 1280px)
      max-width: 222px
      font-size: 16px
      margin-bottom: 72px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      font-size: 14px
      margin-bottom: 36px
    @media screen and (max-width: 767px)
      font-size: 14px
      max-width: 239px
      margin-bottom: 1.5em
  &__slider
    &-wrap
      position: relative
      max-width: 500px
      margin-right: 100px
      @media screen and (min-width: 1660px) and (max-width: 1919px)
        max-width: 367px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        max-width: 400px
        margin-right: 60px
        top: -40px
      @media screen and (max-width: 1279px)
        max-width: unset
        width: 100%
        margin: 0
    &-item
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      overflow: hidden
    &-img
      max-width: 500px
      height: 528px
      margin: 0 auto 64px
      &:hover img
        transform: scale(1.2)
      @media screen and (min-width: 1660px) and (max-width: 1919px)
        max-width: 367px
        height: 388px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        max-width: 292px
        height: 309px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        max-width: 271px
        height: 287px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        max-width: 292px
        height: 309px
      @media screen and (max-width: 767px)
        width: 77%
        height: auto
        margin: 0 auto 44px
    &-info
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
    &-name
      color: $colorGreen
      font-size: 20px
      line-height: 24px
      width: 310px
      padding-right: 57px
      @media screen and (max-width: 1279px)
        padding-right: 46px
        font-size: 16px
        line-height: 19px
        font-weight: 600
    &-price
      color: $colorSecond
      font-weight: 600
      font-size: 20px
      line-height: 24px
      white-space: nowrap

  &__arrow
    cursor: pointer
    position: absolute
    z-index: 1
    top: 242px
    @media screen and (min-width: 1660px) and (max-width: 1919px)
      top: 170px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      top: 133px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      top: 120px
    @media screen and (max-width: 1279px)
      top: 30%
    .arrow
      border: solid $colorGreen
      border-width: 0 3px 3px 0
      padding: 20px
      display: block
      transition: border-color 0.3s ease
      &:hover
        border-color: $colorDarkGreen
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        padding: 13px
      @media screen and (max-width: 1279px)
        padding: 14px
    &.left
      transform: rotate(135deg)
      left: -100px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        left: -40px
      @media screen and (max-width: 1279px)
        left: 0
    &.right
      transform: rotate(-45deg)
      right: -100px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        right: -40px
      @media screen and (max-width: 1279px)
        right: 0