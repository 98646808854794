$red: #f01e2c

.dn
  display: none

[v-cloak]
  display: none


.hidden-text-block
  display: none

.js_wrap_input
  .error-msg
    display: none
  &.error
    ul
      display: block
    input
      border: 1px solid $red !important
    .error-msg
      display: block
      z-index: 1
