.portfolio
  &__wrap
    @media screen and (max-width: 1279px)
      padding-bottom: 62px
  &__desc
    @media screen and (max-width: 767px)
      max-width: 236px

  &__gallery
    display: grid
    grid-gap: 32px
    grid-template-columns: repeat(3, 367px)
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      grid-template-columns: repeat(3, 292px)
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      grid-template-columns: repeat(3, 271px)
    @media screen and (min-width: 768px) and (max-width: 1279px)
      grid-template-columns: repeat(3, 208px)
    @media screen and (max-width: 767px)
      grid-template-columns: repeat(99, 239px)
      overflow-x: auto
      grid-gap: 22px
      &::-webkit-scrollbar
        width: 0
        height: 0
        background: transparent
        -ms-overflow-style: none
        scrollbar-width: none
        overflow: -moz-scrollbars-none

    &-item
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      &:nth-child(2n)
        flex-direction: column-reverse
      &:hover
        .portfolio__gallery-title:before
          width: 100px
        img
          transform: scale(1.2)
    &-img
      width: 367px
      height: 446px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        width: 292px
        height: 371px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        width: 271px
        height: 344px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        width: 208px
        height: 264px
      @media screen and (max-width: 767px)
        width: 239px
        height: 303px
    &-box
      padding: 32px
    &-title
      display: block
      color: $colorGreen
      padding-bottom: 8px
      font-weight: 600
      font-size: 20px
      line-height: 24px
      position: relative
      &:before
        content: ""
        position: absolute
        height: 3px
        width: 0
        background-color: $colorGreen
        transition: width 0.3s ease-in-out
        left: 0
        top: -10px
    &-desc
      font: normal normal normal 14px/16px Inter
      max-width: 200px
      color: #000

.portfolio-page
  &__wrap
    max-width: 1190px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-wrap: wrap
    gap: 64px 32px
    @media screen and (max-width: 1279px)
      gap: 44px
      .portfolio__gallery-img
        width: 100%
        max-width: 667px
  &__item
    @media screen and (min-width: 1660px)
      width: 367px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 292px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 271px
    &:hover
      .portfolio__gallery-title:before
        width: 100px
      img
        transform: scale(1.2)
    &_big
      @media screen and (min-width: 1660px)
        width: 667px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        width: 508px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        width: 473px
      .portfolio__gallery-img
        width: 100%
        @media screen and (max-width: 1279px)
          width: 100%
          max-width: 667px
    &_left
      margin-left: auto
      @media screen and (max-width: 1279px)
        width: 80%
    &_right
      margin-right: auto
      @media screen and (max-width: 1279px)
        width: 80%
.portfolio-detailed
  &__title
    max-width: 766px
  &__wrap
    width: 1165px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-wrap: wrap
    gap: 32px
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      width: 100%
    @media screen and (max-width: 1279px)
      width: 100%
  &__img
    width: 633px
    aspect-ratio: 633/668
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      width: 50%
    @media screen and (max-width: 1279px)
      width: 100%
      aspect-ratio: 326/257
    &:hover img
      @media screen and (min-width: 1280px)
        transform: scale(1.2)
  &__text
    font: normal normal normal 16px/28px Inter
    color: #414141
    margin-left: auto
    width: 367px
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      margin-left: unset
      width: calc(50% - 32px)
    @media screen and (max-width: 1279px)
      width: 100%
  &__item
    width: 367px
    height: 466px
    &:hover img
      transform: scale(1.2)
    @media screen and (max-width: 1279px)
      min-width: 239px
      height: unset
      aspect-ratio: 239/193
    &-wrap
      display: flex
      align-items: flex-start
      justify-content: flex-start
      gap: 32px
      @media screen and (max-width: 1279px)
        overflow-x: auto
        gap: 22px
        &::-webkit-scrollbar
          width: 0
          height: 0
          background: transparent
          -ms-overflow-style: none
          scrollbar-width: none
          overflow: -moz-scrollbars-none
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 292px
      height: 371px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 271px
      height: 344px
    &_big
      width: 633px
  &__review
    background-color: #fff
    padding: 68px 0 36px
    margin: 88px 0
    @media screen and (max-width: 1279px)
      padding: 32px 17px 48px
    &-wrap
      max-width: 1032px
    &-box
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
      max-width: 859px
      margin-left: auto
      @media screen and (max-width: 1279px)
        position: relative
        max-width: 850px
        width: calc(100% + 30px)
        left: -15px
        top: 0
        .reviews__slider-img
          min-width: 87px
          height: 87px
        .reviews__slider-text
          width: calc(100% + 103px)
          left: -103px
        .reviews__slider-position
          min-height: 3em
  &__bottom
    @media screen and (max-width: 1279px)
      display: none