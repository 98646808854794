.side-menu
  position: fixed
  z-index: 2
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  width: 42px
  bottom: 50px
  top: 68px
  left: 48px
  @media screen and (min-width: 768px) and (max-width: 1279px)
    left: 40px
    top: 32px
  @media screen and (max-width: 767px)
    left: 16px
    top: 26px
  &__burger
    cursor: pointer
    width: 42px
    height: 25px
    min-height: 25px
    position: relative
    z-index: 3
    transition: height 0.3s ease-in-out
    @media screen and (max-width: 767px)
      transform: scale(0.76)
      transform-origin: left
    span
      position: absolute
      top: calc(50% - 2px)
      left: 0
      right: 0
      height: 4px
      background-color: #000
      transition: all 0.3s ease
    &:before, &:after
      content: ""
      position: absolute
      left: 0
      background-color: #000
      display: block
      width: 100%
      height: 4px
      transition: all 0.3s ease
    &.invert
      span
        background-color: #fff
      &:before, &:after
        background-color: #fff
    &:before
      top: 0
      transform-origin: 5px 0
    &:after
      bottom: 0
      transform-origin: 5px 100%
    &:hover
      height: 28px
  &__copy
    writing-mode: tb-rl
    color: #414141
    font-size: 16px
    margin: auto 0 60px
    white-space: nowrap
    transform: rotate(-180deg)
    transition: all 0.3s ease
    &.invert
      color: #fff
    @media screen and (max-width: 1279px)
      display: none
    span
      display: inline-flex
      transform: rotate(90deg)
  &__socials
    display: flex
    &_desktop
      flex-direction: column
      justify-content: flex-start
      align-items: center
      @media screen and (max-width: 1279px)
        display: none
    &_mobile
      flex-direction: row
      align-items: center
      justify-content: space-between
      position: absolute
      bottom: 5%
      left: 23.3%
      right: 23.3%
  &__social
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 20px
    &:last-of-type
      margin-bottom: 0
  &__list
    position: fixed
    top: 0
    bottom: 0
    left: -60vw
    width: 57vw
    z-index: 2
    background-color: $colorSecond
    padding: 30px 30px 150px
    transition: all 0.3s ease-in-out
    background-repeat: no-repeat
    background-position: right center
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 30px
    overflow-y: auto
    text-align: center
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      display: flex
      align-items: center
      justify-content: center
      padding: 30px 30px 120px
      background-size: 170px 337px
    @media screen and (max-width: 1279px)
      width: 100vw
      left: -101vw
      padding: 30px 30px 100px
  &__item
    margin-bottom: 50px
    list-style-type: none
    &:last-of-type
      margin-bottom: 0
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      max-width: 209px
      margin-bottom: 40px
    @media screen and (max-width: 1279px)
      margin-bottom: 32px
  &__link
    color: #fff
    font-size: 28px
    line-height: 36px
    @media screen and (max-width: 1279px)
      font-size: 18px
      line-height: 28px
  &.active
    .side-menu__list
      left: 0
    .side-menu__burger
      &:hover
        height: 25px
      span
        display: none
      &::before
        background-color: #fff
        transform: rotate(45deg)
      &::after
        transform: rotate(-45deg)
        background-color: #fff
    .side-menu__phone
      position: absolute
      right: 40px
      top: 20px
      color: #fff
      font-size: 16px
      line-height: 19px
    .side-menu__callback
      color: $colorDarkGreen
      font-size: 18px
      white-space: nowrap
    .side-menu__social
      margin: 0
      svg path
        fill: #fff
    .side-menu__mail
      text-transform: uppercase
      font-size: 28px
      color: #fff
      @media screen and (min-width: 1280px)
        display: none
      @media screen and (max-width: 1279px)
        font-size: 18px