.catalog-page
  &__wrap
    max-width: 1190px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-wrap: wrap
    gap: 56px 32px
  &__item
    width: 367px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 292px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      width: 271px
    &:hover
      .portfolio__gallery-title:before
        width: 100px
      img
        transform: scale(1.2)
    &_big
      width: 500px
  &__img
    width: 100%
    height: 262px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      height: 210px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      height: 195px
  &__text
    max-width: 234px
    color: #414141
    font: normal normal normal 16px/28px Inter
.catalog-detailed
  &__info
    display: flex
    align-items: flex-start
    justify-content: flex-start
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      gap: 32px
    @media screen and (max-width: 1279px)
      flex-direction: column
  &__img
    width: 633px
    height: 500px
    margin-right: 165px
    &:hover img
      transform: scale(1.2)
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      width: 50%
      margin-right: 0
      height: auto
    @media screen and (max-width: 1279px)
      width: 100%
      height: auto
      margin: 0 0 32px
  &__text
    width: 367px
    min-height: 500px
    padding-bottom: 100px
    font: normal normal normal 16px/28px Inter
    color: #414141
    position: relative
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      width: auto
      max-width: 50%
      min-height: unset
    @media screen and (max-width: 1279px)
      min-height: unset
      width: 100%
  &__button
    position: absolute
    bottom: 0
    left: 0
    white-space: nowrap
    @media screen and (max-width: 1279px)
      margin-top: 64px