.about
  &__wrap
    width: 1130px
    font: normal normal normal 16px/28px Inter
    position: relative
    color: #414141
    @media screen and (max-width: 1659px)
      width: 100%
  &__aside
    width: 200px
    margin-left: auto
    font: normal normal normal 14px/24px Inter
    color: #414141
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      margin-left: unset
  &__subtitle
    font-size: 48px
    line-height: 56px
    padding-bottom: 37px
    color: #2F3A41
    width: 584px
    font-weight: 500
  &__img
    &:hover img
      transform: scale(1.2)
    &_big
      width: 899px
    &-wrap
      display: flex
      align-items: flex-start
      justify-content: flex-start
      gap: 32px
  &__float
    position: relative
    left: -135px
  &__blocktitle
    font-size: 28px
    line-height: 32px
    font-weight: 500
    color: #2F3A41
    padding-bottom: 18px
  &__list-title
    font-size: 20px
    line-height: 32px
    font-weight: 500
    padding-bottom: 18px
  &__longtext
    p
      padding-bottom: 1.5em
