.output
  &__wrap
    @media screen and (max-width: 1279px)
      padding-bottom: 56px
  &__gallery
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-wrap: wrap
    gap: 32px
    @media screen and (max-width: 767px)
      overflow-x: auto
      flex-wrap: nowrap
      gap: 34px
      &::-webkit-scrollbar
        width: 0
        height: 0
        background: transparent
        -ms-overflow-style: none
        scrollbar-width: none
        overflow: -moz-scrollbars-none
      &::after
        top: 7px
    &-box
      padding: 32px
      max-width: 264px
      @media screen and (max-width: 1279px)
        position: unset
        padding: 32px 32px 0
    &-item
      display: flex
      flex-direction: column
      position: relative
      @media screen and (max-width: 767px)
        position: unset
        min-width: 100%
      &:nth-of-type(4n+1)
        .output__gallery-box
          position: absolute
          top: 100%
          left: 0
          @media screen and (min-width: 1366px) and (max-width: 1659px)
            max-width: 216px
          @media screen and (min-width: 1280px) and (max-width: 1365px)
            max-width: 202px
          @media screen and (max-width: 1279px)
            position: unset
      &:nth-of-type(4n+2)
        flex-direction: row
        @media screen and (max-width: 1279px)
          flex-direction: column
      &:nth-of-type(4n+3)
        margin-left: 264px
        @media screen and (min-width: 1366px) and (max-width: 1659px)
          margin-left: 216px
        @media screen and (min-width: 1280px) and (max-width: 1365px)
          margin-left: 202px
        @media screen and (max-width: 1279px)
          margin-left: auto
      &:hover
        .output__gallery-title:before
          width: 100px
        img
          transform: scale(1.2)
    &-title
      display: block
      color: $colorGreen
      padding-bottom: 8px
      font-weight: 600
      font-size: 20px
      line-height: 24px
      position: relative
      &:before
        content: ""
        position: absolute
        height: 3px
        width: 0
        background-color: $colorGreen
        transition: width 0.3s ease-in-out
        left: 0
        top: -10px
    &-desc
      font: normal normal normal 14px/16px Inter
      max-width: 200px
      color: #000
    &-img
      height: 262px
      max-width: 367px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        height: 210px
        max-width: 292px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        height: 195px
        max-width: 271px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        height: 171px
        max-width: 208px
      @media screen and (max-width: 1279px)
        min-width: 100%
        height: unset
      &_big
        max-width: 500px
        @media screen and (min-width: 1366px) and (max-width: 1659px)
          max-width: 400px
        @media screen and (min-width: 1280px) and (max-width: 1365px)
          max-width: 372px
        @media screen and (min-width: 768px) and (max-width: 1279px)
          width: 328px