.reviews
  position: relative
  &__title
    @media screen and (min-width: 1280px)
      max-width: 500px
    @media screen and (max-width: 767px)
      max-width: 300px
  &__wrap
    @media screen and (min-width: 768px) and (max-width: 1279px)
      padding-bottom: 62px
    @media screen and (max-width: 767px)
      padding-bottom: 56px
  &__slider
    width: 930px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      width: 865px
    @media screen and (max-width: 1279px)
      width: 100%
    @media screen and (max-width: 767px)
      overflow: hidden
      &::after
        top: 134px
    .slick-list
      overflow: unset
      @media screen and (max-width: 767px)
        overflow: hidden
        margin: 0 -20px
    &-item-wrap.slick-slide
      display: flex
      padding-right: 165px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        padding-right: 140px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        padding: 0
        margin-right: 32px
        width: 568px
      @media screen and (max-width: 767px)
        padding: 0
        margin-right: 20px
        &:last-of-type
          margin-right: 0
    &-item
      background-color: #fff
      padding: 44px 56px
      display: flex
      align-items: flex-start
      justify-content: flex-start
      position: relative
      width: 766px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        width: 724px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        width: 675px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        padding: 44px 56px 80px
        width: 568px
      @media screen and (max-width: 767px)
        padding: 58px 50px 17px
        width: 100%
    &-img
      border-radius: 50%
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      width: 130px
      min-width: 130px
      height: 130px
      margin-right: 32px
      @media screen and (max-width: 767px)
        width: 65px
        min-width: 65px
        height: 65px
        margin-right: 16px
      img
        object-fit: cover
    &-info
      overflow: hidden
      @media screen and (max-width: 767px)
        overflow: unset
    &-position
      font-weight: 600
      font-size: 13px
      line-height: 16px
      color: $colorGreen
      padding-bottom: 8px
    &-name
      font-size: 28px
      line-height: 32px
      color: #2F3A41
      padding-bottom: 18px
      @media screen and (max-width: 767px)
        font-size: 20px
        line-height: 24px
        padding-bottom: 33px
        font-weight: 600
        white-space: nowrap
    &-text
      font: normal normal normal 14px/24px Inter
      color: #000
      @media screen and (max-width: 767px)
        position: relative
        left: -81px
        width: calc(100% + 81px)
    &-arrow-box
      position: relative
      top: 88px
      width: 190px
      height: 49px
      display: flex
      align-items: center
      justify-content: space-between
      color: #414141
      font: normal normal normal 22px/28px Inter
      @media screen and (min-width: 768px) and (max-width: 1279px)
        top: 62px
        padding-bottom: 62px
      @media screen and (max-width: 767px)
        display: none
.reviews-page
  &__wrap
    max-width: 1032px
    column-count: 2
    column-gap: 32px
    list-style-type: none
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      max-width: 831px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      max-width: 766px
    @media screen and (max-width: 1279px)
      column-count: 1
  &__item
    background-color: #fff
    display: grid
    height: auto
    margin-bottom: 32px
    overflow: hidden
    page-break-inside: avoid
    break-inside: avoid-column
    @media screen and (min-width: 1660px)
      padding: 44px 49px
      grid-template-columns: 130px 1fr
      grid-gap: 32px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      padding: 35px 35px 38px
      grid-template-columns: 105px 1fr
      grid-gap: 51px 32px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      padding: 33px 33px 38px
      grid-template-columns: 97px 1fr
      grid-gap: 51px 32px
    @media screen and (max-width: 1279px)
      padding: 29px 29px 34px
      margin-bottom: 28px
      grid-template-columns: 84px 1fr
      grid-gap: 51px 32px
    &_full
      max-width: 1032px
      @media screen and (min-width: 1366px) and (max-width: 1659px)
        max-width: 831px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        max-width: 766px
      @media screen and (max-width: 1279px)
        .reviews-page__item-text
          position: relative
          top: 51px
          left: -116px
          width: calc(100% + 116px)
          padding-bottom: 51px
    &-img
      border-radius: 50%
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      aspect-ratio: 1/1
      img
        object-fit: cover
    &-position
      font-weight: 600
      font-size: 13px
      line-height: 16px
      color: $colorGreen
      padding-bottom: 8px
      @media screen and (max-width: 1279px)
        font-size: 10px
        line-height: 13px
        padding-bottom: 24px
    &-name
      font-size: 28px
      line-height: 32px
      color: #2F3A41
      @media screen and (max-width: 1279px)
        font-size: 20px
        line-height: 24px
    &-text
      font: normal normal normal 16px/28px Inter
      color: #000
      grid-column: 1/3
      grid-row: 2/3
      @media screen and (max-width: 1279px)
        font-size: 14px