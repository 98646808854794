.header
  padding: 54px 0 104px
  @media screen and (min-width: 768px) and (max-width: 1279px)
    padding: 19px 0 75px
  @media screen and (max-width: 767px)
    padding: 12px 0 48px
  &__wrap
    display: flex
    align-items: center
    justify-content: space-between
    gap: 30px
  &__logo
    .logo
      width: 350px
      @media screen and (min-width: 1366px) and (max-width: 1480px)
        width: 280px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        width: 240px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        width: 250px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      margin-left: 120px
    @media screen and (max-width: 767px)
      margin: 0 auto
      .logo
        width: 170px
  &__contact
    display: flex
    align-items: center
    justify-content: flex-end
    gap: 50px
    @media screen and (max-width: 767px)
      display: none
  //&__callback
  //  cursor: pointer
  //  color: $colorGreen
  //  font-size: 16px
  //  padding: 6.5px 20px
  //  border: 2px solid $colorGreen
  //  border-radius: 25px
  //  white-space: nowrap
  //  margin-right: 64px
  //  @media screen and (min-width: 1366px) and (max-width: 1919px)
  //    margin-right: 112px
  //  @media screen and (min-width: 1280px) and (max-width: 1365px)
  //    margin-right: 81px
  //  @media screen and (max-width: 1279px)
  //    display: none
  &__mail
    text-transform: uppercase
    font: 500 24px/29px "Montserrat", sans-serif
    color: #000
    @media screen and (max-width: 1279px)
      display: none
  &__phone
    font-size: 24px
    font-weight: 500
    letter-spacing: 4.8px
    color: #000
    white-space: nowrap
    .grey
      color: #606060