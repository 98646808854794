.footer
  background-color: $colorSecond
  &__wrap
    display: flex
    align-items: center
    justify-content: space-between
    height: 80px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      height: 160px
      display: block
      padding: 32px 0
    @media screen and (max-width: 767px)
      height: unset
      flex-direction: column
      justify-content: flex-start
      padding: 28px 0 22px
  &__nav
    display: flex
    align-items: center
    justify-content: flex-start
    @media screen and (min-width: 768px) and (max-width: 1279px)
      justify-content: space-between
      padding-bottom: 44px
    @media screen and (max-width: 767px)
      flex-direction: column
    li
      list-style-type: none
      @media screen and (max-width: 767px)
        margin-bottom: 32px
  &__link
    color: #fff
    white-space: nowrap
    font: normal normal normal 14px/24px Inter
    margin-right: 72px
    @media screen and (min-width: 1366px) and (max-width: 1659px)
      margin-right: 45px
    @media screen and (min-width: 1280px) and (max-width: 1365px)
      margin-right: 32px
    @media screen and (max-width: 1279px)
      text-align: center
      margin-right: 0
  &__dev
    display: flex
    align-items: center
    justify-content: flex-end
    color: #ffffff
    font: normal normal normal 12px/28px Inter
    @media screen and (min-width: 768px) and (max-width: 1279px)
      justify-content: flex-start
    &-logo
      border-radius: 50%
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      margin-right: 16px
      width: 36px
      height: 36px
      img
        object-fit: cover