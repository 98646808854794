.slider-block
  background-color: $colorSecond
  color: #fff
  @media screen and (max-width: 767px)
    &::after
      top: 400px
  &__wrap
    position: relative
    @media screen and (max-width: 1279px)
      margin: 0 -15px 88px
  &__item
    position: relative
    @media screen and (max-width: 1279px)
      padding: 0 15px
    &-img
      position: absolute
      right: 0
      top: 0
      bottom: 0
      overflow: hidden
      display: flex
      justify-content: flex-end
      @media screen and (min-width: 1920px)
        width: 1210px
      @media screen and (min-width: 1660px) and (max-width: 1919px)
        width: 1080px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        width: 775px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        position: unset
        width: 100%
        max-height: 588px
      @media screen and (max-width: 767px)
        position: unset
        width: 100%
      img
        object-fit: cover
    &-info
      position: relative
      z-index: 1
      max-width: 400px
      padding: 100px 0 63px
      @media screen and (min-width: 1920px)
        height: 1080px
      @media screen and (min-width: 1660px) and (max-width: 1919px)
        height: 900px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        height: 768px
      @media screen and (min-width: 1280px) and (max-width: 1365px)
        height: 800px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        height: auto
        max-width: 568px
        padding: 42px 0 70px
      @media screen and (max-width: 767px)
        padding: 21px 0 18px
        height: 231px
    &-title
      padding-right: 100px
      max-width: 584px
      color: #fff
      @media screen and (min-width: 768px) and (max-width: 1279px)
        max-width: initial
        padding-right: 0
      @media screen and (max-width: 767px)
        font-size: 24px
        padding-bottom: 21px
    &-greentext
      font-size: 20px
      line-height: 24px
      padding: 0 100px 26px 0
      color: $colorGreen
      font-weight: 600
      @media screen and (min-width: 1920px)
        font-size: 20px
      @media screen and (min-width: 1280px) and (max-width: 1919px)
        font-size: 13px
        padding-bottom: 18px
      @media screen and (max-width: 1279px)
        font-size: 13px
        line-height: 16px
        padding-bottom: 18px
    &-text
      font: normal normal normal 16px/28px Inter
      padding: 0 100px 62px 0
      @media screen and (min-width: 1660px) and (max-width: 1919px)
        padding-right: 150px
      @media screen and (min-width: 768px) and (max-width: 1659px)
        padding: 0 0 40px
      @media screen and (max-width: 767px)
        display: none
    &-link
      color: #fff
    &-year
      position: absolute
      font: normal normal normal 18px/21px Inter
      left: 0
      bottom: 63px
      @media screen and (min-width: 1280px) and (max-width: 1659px)
        bottom: 54px
      @media screen and (min-width: 768px) and (max-width: 1279px)
        bottom: 84px
        right: 0
        left: unset
      @media screen and (max-width: 767px)
        bottom: 18px
        font-size: 14px
        line-height: 16px
  &__arrow-box
    width: 186px
    height: 49px
    display: flex
    align-items: center
    justify-content: space-between
    position: absolute
    right: 113px
    bottom: 48px
    font-size: 22px
    line-height: 28px
    color: #414141
    @media screen and (min-width: 1660px) and (max-width: 1919px)
      right: 181px
      bottom: 39px
    @media screen and (min-width: 1280px) and (max-width: 1659px)
      right: 159px
      bottom: 40px
    @media screen and (min-width: 768px) and (max-width: 1279px)
      bottom: 30px
      right: 60px
    @media screen and (max-width: 767px)
      display: none
  &__counter
    font: normal normal normal 22px/28px Inter
    letter-spacing: 2.2px