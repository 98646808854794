@charset "UTF-8";
/* Slider */
@import "~magnific-popup/dist/magnific-popup.css";
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.dn {
  display: none;
}

[v-cloak] {
  display: none;
}

.hidden-text-block {
  display: none;
}

.js_wrap_input .error-msg {
  display: none;
}
.js_wrap_input.error ul {
  display: block;
}
.js_wrap_input.error input {
  border: 1px solid #f01e2c !important;
}
.js_wrap_input.error .error-msg {
  display: block;
  z-index: 1;
}

* {
  box-sizing: border-box;
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  font: normal normal 14px/1.2 "Montserrat", sans-serif;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  min-width: 100%;
  background-color: #ECF0F5;
  overflow-x: hidden;
}

main {
  min-height: 100vh;
}

header,
footer,
section {
  position: relative;
}

.container-outer {
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 1920px) {
  .container-outer {
    max-width: 1830px;
  }
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .container-outer {
    max-width: 1564px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .container-outer {
    max-width: 1270px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .container-outer {
    max-width: 1184px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .container-outer {
    padding: 0 48px;
  }
}
@media screen and (max-width: 1279px) {
  .container-outer {
    width: 100%;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    width: 1430px;
  }
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .container {
    width: 1298px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .container {
    width: 1040px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .container {
    width: 978px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .container {
    width: 100%;
    padding: 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    padding: 0 16px;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    margin-left: auto;
  }
}

ol, ul, li, p, h1, h2, h3, h4 {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 62px;
  line-height: 72px;
  color: #2F3A41;
}
@media screen and (max-width: 1279px) {
  h1 {
    font-size: 32px;
    line-height: 36px;
  }
}

h2 {
  font-size: 48px;
  line-height: 56px;
  color: #2F3A41;
}
@media screen and (max-width: 1279px) {
  h2 {
    font-size: 24px;
    line-height: 28px;
  }
}

h3 {
  font-size: 28px;
  line-height: 32px;
  color: #2F3A41;
}
@media screen and (max-width: 1279px) {
  h3 {
    font-size: 20px;
    line-height: 24px;
  }
}

h4 {
  font-size: 20px;
  line-height: 24px;
  color: #2F3A41;
}
@media screen and (max-width: 1279px) {
  h4 {
    font-size: 16px;
    line-height: 19px;
  }
}

a {
  text-decoration: none;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

textarea {
  resize: none;
  font-size: 16px;
}

main.main {
  overflow: hidden;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.img {
  overflow: hidden;
}
.img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden {
  display: none;
}

.mobile {
  display: none;
}
@media screen and (max-width: 1279px) {
  .mobile {
    display: block;
  }
}

.link {
  position: relative;
  font-size: 28px;
  line-height: 1.8;
  color: #323F45;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .link {
    font-size: 20px;
    font-weight: 600;
  }
}
.link .arrow {
  position: relative;
  width: 165px;
  height: 3px;
  margin-left: 24px;
  background-color: #008066;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .link .arrow {
    width: 70px;
  }
}
.link .arrow:before, .link .arrow:after {
  content: "";
  position: absolute;
  height: 3px;
  background-color: #008066;
  width: 20px;
  right: 0;
  transition: all 0.3s ease-in-out;
  transform-origin: right center;
}
.link:hover .arrow {
  width: 200px;
  border-radius: 1px;
}
.link:hover .arrow:before {
  transform: rotate(45deg) translateY(1px);
}
.link:hover .arrow:after {
  transform: rotate(-45deg) translateY(-1px);
}

.nowrap {
  white-space: nowrap;
}

.title {
  color: #2F3A41;
  font-size: 48px;
  line-height: 1.17;
  padding-bottom: 37px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 21px;
  }
}

.block {
  margin-bottom: 224px;
}
@media screen and (max-width: 1279px) {
  .block {
    margin-bottom: 88px;
  }
}
.block__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 80px;
}
@media screen and (max-width: 1279px) {
  .block__wrap {
    flex-direction: column;
  }
}
.block__desc {
  max-width: 665px;
  padding-right: 163px;
  font: normal normal normal 16px/28px Inter;
  color: #414141;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .block__desc {
    max-width: 540px;
    padding-right: 140px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .block__desc {
    max-width: 505px;
    padding-right: 135px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .block__desc {
    max-width: 568px;
  }
}
@media screen and (max-width: 1279px) {
  .block__desc {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 32px;
    padding-right: 0;
  }
}

.default-arrow {
  cursor: pointer;
  width: 49px;
  height: 49px;
}
.default-arrow:hover .a {
  fill: #182638;
}
.default-arrow:hover .b {
  stroke: #008066;
}

.section {
  padding-bottom: 224px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 25px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
}
@media screen and (max-width: 1279px) {
  .breadcrumbs {
    display: none;
  }
}
.breadcrumbs__link {
  color: #008066;
  white-space: nowrap;
  display: block;
  margin-right: 56px;
  position: relative;
}
.breadcrumbs__link:before, .breadcrumbs__link:after {
  content: "";
  position: absolute;
  display: block;
}
.breadcrumbs__link:before {
  width: 24px;
  height: 1px;
  background-color: #008066;
  left: calc(100% + 16px);
  top: calc(50% - 0px);
}
.breadcrumbs__link:after {
  border: solid #008066;
  border-width: 0 1px 1px 0;
  left: calc(100% + 33px);
  top: calc(50% - 3px);
  padding: 3px;
  transform: rotate(-45deg);
}
.breadcrumbs__link:last-of-type {
  margin-right: 0;
  color: #434343;
}
.breadcrumbs__link:last-of-type:before, .breadcrumbs__link:last-of-type:after {
  content: unset;
}

.page-title {
  font: normal normal 600 62px/1.16 Montserrat;
  padding-bottom: 64px;
  color: #2F3A41;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .page-title {
    padding-bottom: 45px;
    font-size: 56px;
  }
}
@media screen and (max-width: 1279px) {
  .page-title {
    font-size: 32px;
    line-height: 36px;
    padding-bottom: 64px;
  }
}
.page-title_short {
  max-width: 769px;
}

.button {
  display: inline-flex;
  cursor: pointer;
  font: 500 18px/20px Montserrat;
  padding: 20px 34px;
  color: #fff;
  border-radius: 12px;
  background-color: #008066;
  transition: background-color 0.3s ease-in-out;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .button {
    padding: 14px 25px;
  }
}
.button:hover {
  background-color: #0A5546;
}

.green {
  color: #008066;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.img img {
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  .mobile-swipe {
    position: relative;
  }
  .mobile-swipe::after {
    content: "";
    width: 100px;
    height: 100px;
    background: url("/public/build/swype.svg") no-repeat;
    position: absolute;
    left: 50vw;
    top: 78px;
    transform: translateX(-50%);
  }
}

.inverting {
  transition: all 0.3s ease;
}

.header {
  padding: 54px 0 104px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header {
    padding: 19px 0 75px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    padding: 12px 0 48px;
  }
}
.header__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.header__logo .logo {
  width: 350px;
}
@media screen and (min-width: 1366px) and (max-width: 1480px) {
  .header__logo .logo {
    width: 280px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .header__logo .logo {
    width: 240px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header__logo .logo {
    width: 250px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header__logo {
    margin-left: 120px;
  }
}
@media screen and (max-width: 767px) {
  .header__logo {
    margin: 0 auto;
  }
  .header__logo .logo {
    width: 170px;
  }
}
.header__contact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
}
@media screen and (max-width: 767px) {
  .header__contact {
    display: none;
  }
}
.header__mail {
  text-transform: uppercase;
  font: 500 24px/29px "Montserrat", sans-serif;
  color: #000;
}
@media screen and (max-width: 1279px) {
  .header__mail {
    display: none;
  }
}
.header__phone {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 4.8px;
  color: #000;
  white-space: nowrap;
}
.header__phone .grey {
  color: #606060;
}

.footer {
  background-color: #182638;
}
.footer__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .footer__wrap {
    height: 160px;
    display: block;
    padding: 32px 0;
  }
}
@media screen and (max-width: 767px) {
  .footer__wrap {
    height: unset;
    flex-direction: column;
    justify-content: flex-start;
    padding: 28px 0 22px;
  }
}
.footer__nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .footer__nav {
    justify-content: space-between;
    padding-bottom: 44px;
  }
}
@media screen and (max-width: 767px) {
  .footer__nav {
    flex-direction: column;
  }
}
.footer__nav li {
  list-style-type: none;
}
@media screen and (max-width: 767px) {
  .footer__nav li {
    margin-bottom: 32px;
  }
}
.footer__link {
  color: #fff;
  white-space: nowrap;
  font: normal normal normal 14px/24px Inter;
  margin-right: 72px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .footer__link {
    margin-right: 45px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .footer__link {
    margin-right: 32px;
  }
}
@media screen and (max-width: 1279px) {
  .footer__link {
    text-align: center;
    margin-right: 0;
  }
}
.footer__dev {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffffff;
  font: normal normal normal 12px/28px Inter;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .footer__dev {
    justify-content: flex-start;
  }
}
.footer__dev-logo {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
.footer__dev-logo img {
  object-fit: cover;
}

.side-menu {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 42px;
  bottom: 50px;
  top: 68px;
  left: 48px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .side-menu {
    left: 40px;
    top: 32px;
  }
}
@media screen and (max-width: 767px) {
  .side-menu {
    left: 16px;
    top: 26px;
  }
}
.side-menu__burger {
  cursor: pointer;
  width: 42px;
  height: 25px;
  min-height: 25px;
  position: relative;
  z-index: 3;
  transition: height 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .side-menu__burger {
    transform: scale(0.76);
    transform-origin: left;
  }
}
.side-menu__burger span {
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  right: 0;
  height: 4px;
  background-color: #000;
  transition: all 0.3s ease;
}
.side-menu__burger:before, .side-menu__burger:after {
  content: "";
  position: absolute;
  left: 0;
  background-color: #000;
  display: block;
  width: 100%;
  height: 4px;
  transition: all 0.3s ease;
}
.side-menu__burger.invert span {
  background-color: #fff;
}
.side-menu__burger.invert:before, .side-menu__burger.invert:after {
  background-color: #fff;
}
.side-menu__burger:before {
  top: 0;
  transform-origin: 5px 0;
}
.side-menu__burger:after {
  bottom: 0;
  transform-origin: 5px 100%;
}
.side-menu__burger:hover {
  height: 28px;
}
.side-menu__copy {
  writing-mode: tb-rl;
  color: #414141;
  font-size: 16px;
  margin: auto 0 60px;
  white-space: nowrap;
  transform: rotate(-180deg);
  transition: all 0.3s ease;
}
.side-menu__copy.invert {
  color: #fff;
}
@media screen and (max-width: 1279px) {
  .side-menu__copy {
    display: none;
  }
}
.side-menu__copy span {
  display: inline-flex;
  transform: rotate(90deg);
}
.side-menu__socials {
  display: flex;
}
.side-menu__socials_desktop {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1279px) {
  .side-menu__socials_desktop {
    display: none;
  }
}
.side-menu__socials_mobile {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 5%;
  left: 23.3%;
  right: 23.3%;
}
.side-menu__social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.side-menu__social:last-of-type {
  margin-bottom: 0;
}
.side-menu__list {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -60vw;
  width: 57vw;
  z-index: 2;
  background-color: #182638;
  padding: 30px 30px 150px;
  transition: all 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-position: right center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  overflow-y: auto;
  text-align: center;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .side-menu__list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 30px 120px;
    background-size: 170px 337px;
  }
}
@media screen and (max-width: 1279px) {
  .side-menu__list {
    width: 100vw;
    left: -101vw;
    padding: 30px 30px 100px;
  }
}
.side-menu__item {
  margin-bottom: 50px;
  list-style-type: none;
}
.side-menu__item:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .side-menu__item {
    max-width: 209px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1279px) {
  .side-menu__item {
    margin-bottom: 32px;
  }
}
.side-menu__link {
  color: #fff;
  font-size: 28px;
  line-height: 36px;
}
@media screen and (max-width: 1279px) {
  .side-menu__link {
    font-size: 18px;
    line-height: 28px;
  }
}
.side-menu.active .side-menu__list {
  left: 0;
}
.side-menu.active .side-menu__burger:hover {
  height: 25px;
}
.side-menu.active .side-menu__burger span {
  display: none;
}
.side-menu.active .side-menu__burger::before {
  background-color: #fff;
  transform: rotate(45deg);
}
.side-menu.active .side-menu__burger::after {
  transform: rotate(-45deg);
  background-color: #fff;
}
.side-menu.active .side-menu__phone {
  position: absolute;
  right: 40px;
  top: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
}
.side-menu.active .side-menu__callback {
  color: #0A5546;
  font-size: 18px;
  white-space: nowrap;
}
.side-menu.active .side-menu__social {
  margin: 0;
}
.side-menu.active .side-menu__social svg path {
  fill: #fff;
}
.side-menu.active .side-menu__mail {
  text-transform: uppercase;
  font-size: 28px;
  color: #fff;
}
@media screen and (min-width: 1280px) {
  .side-menu.active .side-menu__mail {
    display: none;
  }
}
@media screen and (max-width: 1279px) {
  .side-menu.active .side-menu__mail {
    font-size: 18px;
  }
}

.banner {
  padding-bottom: 100px;
}
@media screen and (max-width: 1279px) {
  .banner {
    padding-bottom: 87px;
  }
}
.banner__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 1279px) {
  .banner__wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.banner__info {
  max-width: 674px;
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .banner__info {
    max-width: 641px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .banner__info {
    max-width: 560px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .banner__info {
    width: 505px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__info {
    width: 100%;
    padding-bottom: 89px;
  }
}
.banner__title {
  color: #2F3A41;
  font-size: 62px;
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .banner__title {
    font-size: 32px;
    margin-bottom: 30px;
  }
}
.banner__text {
  font-family: Inter, sans-serif;
  line-height: 1.75;
  color: #414141;
}
@media screen and (min-width: 1280px) {
  .banner__text {
    max-width: 222px;
    font-size: 16px;
    margin-bottom: 72px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .banner__text {
    font-size: 14px;
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 767px) {
  .banner__text {
    font-size: 14px;
    max-width: 239px;
    margin-bottom: 1.5em;
  }
}
.banner__slider-wrap {
  position: relative;
  max-width: 500px;
  margin-right: 100px;
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .banner__slider-wrap {
    max-width: 367px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .banner__slider-wrap {
    max-width: 400px;
    margin-right: 60px;
    top: -40px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__slider-wrap {
    max-width: unset;
    width: 100%;
    margin: 0;
  }
}
.banner__slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.banner__slider-img {
  max-width: 500px;
  height: 528px;
  margin: 0 auto 64px;
}
.banner__slider-img:hover img {
  transform: scale(1.2);
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .banner__slider-img {
    max-width: 367px;
    height: 388px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .banner__slider-img {
    max-width: 292px;
    height: 309px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .banner__slider-img {
    max-width: 271px;
    height: 287px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .banner__slider-img {
    max-width: 292px;
    height: 309px;
  }
}
@media screen and (max-width: 767px) {
  .banner__slider-img {
    width: 77%;
    height: auto;
    margin: 0 auto 44px;
  }
}
.banner__slider-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.banner__slider-name {
  color: #008066;
  font-size: 20px;
  line-height: 24px;
  width: 310px;
  padding-right: 57px;
}
@media screen and (max-width: 1279px) {
  .banner__slider-name {
    padding-right: 46px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}
.banner__slider-price {
  color: #182638;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  white-space: nowrap;
}
.banner__arrow {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 242px;
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .banner__arrow {
    top: 170px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .banner__arrow {
    top: 133px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .banner__arrow {
    top: 120px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__arrow {
    top: 30%;
  }
}
.banner__arrow .arrow {
  border: solid #008066;
  border-width: 0 3px 3px 0;
  padding: 20px;
  display: block;
  transition: border-color 0.3s ease;
}
.banner__arrow .arrow:hover {
  border-color: #0A5546;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .banner__arrow .arrow {
    padding: 13px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__arrow .arrow {
    padding: 14px;
  }
}
.banner__arrow.left {
  transform: rotate(135deg);
  left: -100px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .banner__arrow.left {
    left: -40px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__arrow.left {
    left: 0;
  }
}
.banner__arrow.right {
  transform: rotate(-45deg);
  right: -100px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .banner__arrow.right {
    right: -40px;
  }
}
@media screen and (max-width: 1279px) {
  .banner__arrow.right {
    right: 0;
  }
}

@media screen and (max-width: 1279px) {
  .portfolio__wrap {
    padding-bottom: 62px;
  }
}
@media screen and (max-width: 767px) {
  .portfolio__desc {
    max-width: 236px;
  }
}
.portfolio__gallery {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 367px);
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .portfolio__gallery {
    grid-template-columns: repeat(3, 292px);
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .portfolio__gallery {
    grid-template-columns: repeat(3, 271px);
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .portfolio__gallery {
    grid-template-columns: repeat(3, 208px);
  }
}
@media screen and (max-width: 767px) {
  .portfolio__gallery {
    grid-template-columns: repeat(99, 239px);
    overflow-x: auto;
    grid-gap: 22px;
  }
  .portfolio__gallery::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
  }
}
.portfolio__gallery-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.portfolio__gallery-item:nth-child(2n) {
  flex-direction: column-reverse;
}
.portfolio__gallery-item:hover .portfolio__gallery-title:before {
  width: 100px;
}
.portfolio__gallery-item:hover img {
  transform: scale(1.2);
}
.portfolio__gallery-img {
  width: 367px;
  height: 446px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .portfolio__gallery-img {
    width: 292px;
    height: 371px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .portfolio__gallery-img {
    width: 271px;
    height: 344px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .portfolio__gallery-img {
    width: 208px;
    height: 264px;
  }
}
@media screen and (max-width: 767px) {
  .portfolio__gallery-img {
    width: 239px;
    height: 303px;
  }
}
.portfolio__gallery-box {
  padding: 32px;
}
.portfolio__gallery-title {
  display: block;
  color: #008066;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  position: relative;
}
.portfolio__gallery-title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  background-color: #008066;
  transition: width 0.3s ease-in-out;
  left: 0;
  top: -10px;
}
.portfolio__gallery-desc {
  font: normal normal normal 14px/16px Inter;
  max-width: 200px;
  color: #000;
}

.portfolio-page__wrap {
  max-width: 1190px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 64px 32px;
}
@media screen and (max-width: 1279px) {
  .portfolio-page__wrap {
    gap: 44px;
  }
  .portfolio-page__wrap .portfolio__gallery-img {
    width: 100%;
    max-width: 667px;
  }
}
@media screen and (min-width: 1660px) {
  .portfolio-page__item {
    width: 367px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .portfolio-page__item {
    width: 292px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .portfolio-page__item {
    width: 271px;
  }
}
.portfolio-page__item:hover .portfolio__gallery-title:before {
  width: 100px;
}
.portfolio-page__item:hover img {
  transform: scale(1.2);
}
@media screen and (min-width: 1660px) {
  .portfolio-page__item_big {
    width: 667px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .portfolio-page__item_big {
    width: 508px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .portfolio-page__item_big {
    width: 473px;
  }
}
.portfolio-page__item_big .portfolio__gallery-img {
  width: 100%;
}
@media screen and (max-width: 1279px) {
  .portfolio-page__item_big .portfolio__gallery-img {
    width: 100%;
    max-width: 667px;
  }
}
.portfolio-page__item_left {
  margin-left: auto;
}
@media screen and (max-width: 1279px) {
  .portfolio-page__item_left {
    width: 80%;
  }
}
.portfolio-page__item_right {
  margin-right: auto;
}
@media screen and (max-width: 1279px) {
  .portfolio-page__item_right {
    width: 80%;
  }
}

.portfolio-detailed__title {
  max-width: 766px;
}
.portfolio-detailed__wrap {
  width: 1165px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 32px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .portfolio-detailed__wrap {
    width: 100%;
  }
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__wrap {
    width: 100%;
  }
}
.portfolio-detailed__img {
  width: 633px;
  aspect-ratio: 633/668;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .portfolio-detailed__img {
    width: 50%;
  }
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__img {
    width: 100%;
    aspect-ratio: 326/257;
  }
}
@media screen and (min-width: 1280px) {
  .portfolio-detailed__img:hover img {
    transform: scale(1.2);
  }
}
.portfolio-detailed__text {
  font: normal normal normal 16px/28px Inter;
  color: #414141;
  margin-left: auto;
  width: 367px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .portfolio-detailed__text {
    margin-left: unset;
    width: calc(50% - 32px);
  }
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__text {
    width: 100%;
  }
}
.portfolio-detailed__item {
  width: 367px;
  height: 466px;
}
.portfolio-detailed__item:hover img {
  transform: scale(1.2);
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__item {
    min-width: 239px;
    height: unset;
    aspect-ratio: 239/193;
  }
}
.portfolio-detailed__item-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__item-wrap {
    overflow-x: auto;
    gap: 22px;
  }
  .portfolio-detailed__item-wrap::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .portfolio-detailed__item {
    width: 292px;
    height: 371px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .portfolio-detailed__item {
    width: 271px;
    height: 344px;
  }
}
.portfolio-detailed__item_big {
  width: 633px;
}
.portfolio-detailed__review {
  background-color: #fff;
  padding: 68px 0 36px;
  margin: 88px 0;
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__review {
    padding: 32px 17px 48px;
  }
}
.portfolio-detailed__review-wrap {
  max-width: 1032px;
}
.portfolio-detailed__review-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 859px;
  margin-left: auto;
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__review-box {
    position: relative;
    max-width: 850px;
    width: calc(100% + 30px);
    left: -15px;
    top: 0;
  }
  .portfolio-detailed__review-box .reviews__slider-img {
    min-width: 87px;
    height: 87px;
  }
  .portfolio-detailed__review-box .reviews__slider-text {
    width: calc(100% + 103px);
    left: -103px;
  }
  .portfolio-detailed__review-box .reviews__slider-position {
    min-height: 3em;
  }
}
@media screen and (max-width: 1279px) {
  .portfolio-detailed__bottom {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .output__wrap {
    padding-bottom: 56px;
  }
}
.output__gallery {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 32px;
}
@media screen and (max-width: 767px) {
  .output__gallery {
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 34px;
  }
  .output__gallery::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
  }
  .output__gallery::after {
    top: 7px;
  }
}
.output__gallery-box {
  padding: 32px;
  max-width: 264px;
}
@media screen and (max-width: 1279px) {
  .output__gallery-box {
    position: unset;
    padding: 32px 32px 0;
  }
}
.output__gallery-item {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (max-width: 767px) {
  .output__gallery-item {
    position: unset;
    min-width: 100%;
  }
}
.output__gallery-item:nth-of-type(4n+1) .output__gallery-box {
  position: absolute;
  top: 100%;
  left: 0;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .output__gallery-item:nth-of-type(4n+1) .output__gallery-box {
    max-width: 216px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .output__gallery-item:nth-of-type(4n+1) .output__gallery-box {
    max-width: 202px;
  }
}
@media screen and (max-width: 1279px) {
  .output__gallery-item:nth-of-type(4n+1) .output__gallery-box {
    position: unset;
  }
}
.output__gallery-item:nth-of-type(4n+2) {
  flex-direction: row;
}
@media screen and (max-width: 1279px) {
  .output__gallery-item:nth-of-type(4n+2) {
    flex-direction: column;
  }
}
.output__gallery-item:nth-of-type(4n+3) {
  margin-left: 264px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .output__gallery-item:nth-of-type(4n+3) {
    margin-left: 216px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .output__gallery-item:nth-of-type(4n+3) {
    margin-left: 202px;
  }
}
@media screen and (max-width: 1279px) {
  .output__gallery-item:nth-of-type(4n+3) {
    margin-left: auto;
  }
}
.output__gallery-item:hover .output__gallery-title:before {
  width: 100px;
}
.output__gallery-item:hover img {
  transform: scale(1.2);
}
.output__gallery-title {
  display: block;
  color: #008066;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  position: relative;
}
.output__gallery-title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  background-color: #008066;
  transition: width 0.3s ease-in-out;
  left: 0;
  top: -10px;
}
.output__gallery-desc {
  font: normal normal normal 14px/16px Inter;
  max-width: 200px;
  color: #000;
}
.output__gallery-img {
  height: 262px;
  max-width: 367px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .output__gallery-img {
    height: 210px;
    max-width: 292px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .output__gallery-img {
    height: 195px;
    max-width: 271px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .output__gallery-img {
    height: 171px;
    max-width: 208px;
  }
}
@media screen and (max-width: 1279px) {
  .output__gallery-img {
    min-width: 100%;
    height: unset;
  }
}
.output__gallery-img_big {
  max-width: 500px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .output__gallery-img_big {
    max-width: 400px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .output__gallery-img_big {
    max-width: 372px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .output__gallery-img_big {
    width: 328px;
  }
}

.slider-block {
  background-color: #182638;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .slider-block::after {
    top: 400px;
  }
}
.slider-block__wrap {
  position: relative;
}
@media screen and (max-width: 1279px) {
  .slider-block__wrap {
    margin: 0 -15px 88px;
  }
}
.slider-block__item {
  position: relative;
}
@media screen and (max-width: 1279px) {
  .slider-block__item {
    padding: 0 15px;
  }
}
.slider-block__item-img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 1920px) {
  .slider-block__item-img {
    width: 1210px;
  }
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .slider-block__item-img {
    width: 1080px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .slider-block__item-img {
    width: 775px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .slider-block__item-img {
    position: unset;
    width: 100%;
    max-height: 588px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__item-img {
    position: unset;
    width: 100%;
  }
}
.slider-block__item-img img {
  object-fit: cover;
}
.slider-block__item-info {
  position: relative;
  z-index: 1;
  max-width: 400px;
  padding: 100px 0 63px;
}
@media screen and (min-width: 1920px) {
  .slider-block__item-info {
    height: 1080px;
  }
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .slider-block__item-info {
    height: 900px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .slider-block__item-info {
    height: 768px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .slider-block__item-info {
    height: 800px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .slider-block__item-info {
    height: auto;
    max-width: 568px;
    padding: 42px 0 70px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__item-info {
    padding: 21px 0 18px;
    height: 231px;
  }
}
.slider-block__item-title {
  padding-right: 100px;
  max-width: 584px;
  color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .slider-block__item-title {
    max-width: initial;
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__item-title {
    font-size: 24px;
    padding-bottom: 21px;
  }
}
.slider-block__item-greentext {
  font-size: 20px;
  line-height: 24px;
  padding: 0 100px 26px 0;
  color: #008066;
  font-weight: 600;
}
@media screen and (min-width: 1920px) {
  .slider-block__item-greentext {
    font-size: 20px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .slider-block__item-greentext {
    font-size: 13px;
    padding-bottom: 18px;
  }
}
@media screen and (max-width: 1279px) {
  .slider-block__item-greentext {
    font-size: 13px;
    line-height: 16px;
    padding-bottom: 18px;
  }
}
.slider-block__item-text {
  font: normal normal normal 16px/28px Inter;
  padding: 0 100px 62px 0;
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .slider-block__item-text {
    padding-right: 150px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1659px) {
  .slider-block__item-text {
    padding: 0 0 40px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__item-text {
    display: none;
  }
}
.slider-block__item-link {
  color: #fff;
}
.slider-block__item-year {
  position: absolute;
  font: normal normal normal 18px/21px Inter;
  left: 0;
  bottom: 63px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .slider-block__item-year {
    bottom: 54px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .slider-block__item-year {
    bottom: 84px;
    right: 0;
    left: unset;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__item-year {
    bottom: 18px;
    font-size: 14px;
    line-height: 16px;
  }
}
.slider-block__arrow-box {
  width: 186px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 113px;
  bottom: 48px;
  font-size: 22px;
  line-height: 28px;
  color: #414141;
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .slider-block__arrow-box {
    right: 181px;
    bottom: 39px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .slider-block__arrow-box {
    right: 159px;
    bottom: 40px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .slider-block__arrow-box {
    bottom: 30px;
    right: 60px;
  }
}
@media screen and (max-width: 767px) {
  .slider-block__arrow-box {
    display: none;
  }
}
.slider-block__counter {
  font: normal normal normal 22px/28px Inter;
  letter-spacing: 2.2px;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .quality {
    margin-bottom: 116px;
  }
}
.quality__wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 1279px) {
  .quality__wrap {
    flex-direction: column;
  }
}
.quality__img {
  width: 100%;
  max-width: 234px;
  object-fit: cover;
  position: relative;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .quality__img {
    max-width: 184px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .quality__img {
    max-width: 170px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .quality__img {
    max-width: 100%;
    height: 257px;
    margin: 0 0 32px;
  }
  .quality__img img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    bottom: -50%;
  }
}
@media screen and (max-width: 767px) {
  .quality__img {
    max-width: 100%;
    margin: 0 0 32px;
    height: 170px;
  }
  .quality__img img {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    bottom: 10%;
  }
}
.quality__info {
  margin-left: 165px;
  width: 766px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .quality__info {
    width: 616px;
    margin-left: 140px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .quality__info {
    width: 584px;
    margin-left: 133px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .quality__info {
    width: 568px;
    margin: 0;
  }
}
@media screen and (max-width: 1279px) {
  .quality__info {
    width: 100%;
    margin: 0;
  }
}
.quality__title {
  max-width: 584px;
}
.quality__text {
  font: normal normal normal 16px/28px Inter;
  display: grid;
  grid-gap: 32px;
  color: #414141;
}
@media screen and (min-width: 1660px) {
  .quality__text {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1279px) {
  .quality__text {
    font-size: 14px;
    line-height: 24px;
    grid-template-columns: 1fr;
  }
}

.partners__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  width: 100%;
}
@media screen and (min-width: 1660px) {
  .partners__wrap {
    width: 1160px;
  }
}
@media screen and (max-width: 1279px) {
  .partners__wrap {
    flex-direction: column;
  }
}
.partners__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 116px;
  margin-right: 165px;
  width: 100%;
  max-width: 234px;
  color: #2F3A41;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .partners__left {
    margin-right: 90px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .partners__left {
    max-width: 170px;
    margin-right: 154px;
  }
}
@media screen and (max-width: 1279px) {
  .partners__left {
    gap: 10px;
    margin: 0 0 56px;
    max-width: unset;
  }
}
.partners__left .partner-label {
  font: normal normal normal 16px/24px Inter;
  color: #000;
  width: 150px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .partners__left .partner-label {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .partners__left .partner-label {
    width: 100%;
    font-size: 14px;
  }
}
.partners__info {
  display: grid;
  grid-gap: 116px 37px;
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .partners__info {
    grid-gap: 32px 61px;
  }
}
@media screen and (max-width: 767px) {
  .partners__info {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px 41px;
  }
}
.partners__info .partner {
  text-transform: uppercase;
  color: #6C6C6C;
}
@media screen and (max-width: 767px) {
  .partners__info .partner {
    font-size: 20px;
  }
}

.reviews {
  position: relative;
}
@media screen and (min-width: 1280px) {
  .reviews__title {
    max-width: 500px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__title {
    max-width: 300px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .reviews__wrap {
    padding-bottom: 62px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__wrap {
    padding-bottom: 56px;
  }
}
.reviews__slider {
  width: 930px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews__slider {
    width: 865px;
  }
}
@media screen and (max-width: 1279px) {
  .reviews__slider {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .reviews__slider {
    overflow: hidden;
  }
  .reviews__slider::after {
    top: 134px;
  }
}
.reviews__slider .slick-list {
  overflow: unset;
}
@media screen and (max-width: 767px) {
  .reviews__slider .slick-list {
    overflow: hidden;
    margin: 0 -20px;
  }
}
.reviews__slider-item-wrap.slick-slide {
  display: flex;
  padding-right: 165px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews__slider-item-wrap.slick-slide {
    padding-right: 140px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .reviews__slider-item-wrap.slick-slide {
    padding: 0;
    margin-right: 32px;
    width: 568px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__slider-item-wrap.slick-slide {
    padding: 0;
    margin-right: 20px;
  }
  .reviews__slider-item-wrap.slick-slide:last-of-type {
    margin-right: 0;
  }
}
.reviews__slider-item {
  background-color: #fff;
  padding: 44px 56px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 766px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews__slider-item {
    width: 724px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .reviews__slider-item {
    width: 675px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .reviews__slider-item {
    padding: 44px 56px 80px;
    width: 568px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__slider-item {
    padding: 58px 50px 17px;
    width: 100%;
  }
}
.reviews__slider-img {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  min-width: 130px;
  height: 130px;
  margin-right: 32px;
}
@media screen and (max-width: 767px) {
  .reviews__slider-img {
    width: 65px;
    min-width: 65px;
    height: 65px;
    margin-right: 16px;
  }
}
.reviews__slider-img img {
  object-fit: cover;
}
.reviews__slider-info {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .reviews__slider-info {
    overflow: unset;
  }
}
.reviews__slider-position {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #008066;
  padding-bottom: 8px;
}
.reviews__slider-name {
  font-size: 28px;
  line-height: 32px;
  color: #2F3A41;
  padding-bottom: 18px;
}
@media screen and (max-width: 767px) {
  .reviews__slider-name {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 33px;
    font-weight: 600;
    white-space: nowrap;
  }
}
.reviews__slider-text {
  font: normal normal normal 14px/24px Inter;
  color: #000;
}
@media screen and (max-width: 767px) {
  .reviews__slider-text {
    position: relative;
    left: -81px;
    width: calc(100% + 81px);
  }
}
.reviews__slider-arrow-box {
  position: relative;
  top: 88px;
  width: 190px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #414141;
  font: normal normal normal 22px/28px Inter;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .reviews__slider-arrow-box {
    top: 62px;
    padding-bottom: 62px;
  }
}
@media screen and (max-width: 767px) {
  .reviews__slider-arrow-box {
    display: none;
  }
}

.reviews-page__wrap {
  max-width: 1032px;
  column-count: 2;
  column-gap: 32px;
  list-style-type: none;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews-page__wrap {
    max-width: 831px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .reviews-page__wrap {
    max-width: 766px;
  }
}
@media screen and (max-width: 1279px) {
  .reviews-page__wrap {
    column-count: 1;
  }
}
.reviews-page__item {
  background-color: #fff;
  display: grid;
  height: auto;
  margin-bottom: 32px;
  overflow: hidden;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
@media screen and (min-width: 1660px) {
  .reviews-page__item {
    padding: 44px 49px;
    grid-template-columns: 130px 1fr;
    grid-gap: 32px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews-page__item {
    padding: 35px 35px 38px;
    grid-template-columns: 105px 1fr;
    grid-gap: 51px 32px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .reviews-page__item {
    padding: 33px 33px 38px;
    grid-template-columns: 97px 1fr;
    grid-gap: 51px 32px;
  }
}
@media screen and (max-width: 1279px) {
  .reviews-page__item {
    padding: 29px 29px 34px;
    margin-bottom: 28px;
    grid-template-columns: 84px 1fr;
    grid-gap: 51px 32px;
  }
}
.reviews-page__item_full {
  max-width: 1032px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .reviews-page__item_full {
    max-width: 831px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .reviews-page__item_full {
    max-width: 766px;
  }
}
@media screen and (max-width: 1279px) {
  .reviews-page__item_full .reviews-page__item-text {
    position: relative;
    top: 51px;
    left: -116px;
    width: calc(100% + 116px);
    padding-bottom: 51px;
  }
}
.reviews-page__item-img {
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
}
.reviews-page__item-img img {
  object-fit: cover;
}
.reviews-page__item-position {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #008066;
  padding-bottom: 8px;
}
@media screen and (max-width: 1279px) {
  .reviews-page__item-position {
    font-size: 10px;
    line-height: 13px;
    padding-bottom: 24px;
  }
}
.reviews-page__item-name {
  font-size: 28px;
  line-height: 32px;
  color: #2F3A41;
}
@media screen and (max-width: 1279px) {
  .reviews-page__item-name {
    font-size: 20px;
    line-height: 24px;
  }
}
.reviews-page__item-text {
  font: normal normal normal 16px/28px Inter;
  color: #000;
  grid-column: 1/3;
  grid-row: 2/3;
}
@media screen and (max-width: 1279px) {
  .reviews-page__item-text {
    font-size: 14px;
  }
}

.map {
  position: relative;
}
@media screen and (min-width: 1920px) {
  .map {
    height: 1080px;
  }
}
@media screen and (min-width: 1660px) and (max-width: 1919px) {
  .map {
    height: 898px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .map {
    height: 768px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .map {
    height: 800px;
  }
}
@media screen and (max-width: 767px) {
  .map {
    height: unset;
  }
}
.map__wrap {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 1279px) {
  .map__wrap {
    position: unset;
    height: 100%;
  }
}
.map__box {
  position: relative;
  top: 100px;
  left: 0;
  width: 431px;
  z-index: 1;
  background-color: #182638;
  padding: 36px 32px;
  color: #fff;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .map__box {
    width: 342px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .map__box {
    width: 335px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .map__box {
    width: calc(100% + 80px);
    position: relative;
    left: -40px;
    top: 0;
    padding: 36px 72px 71px;
  }
}
@media screen and (max-width: 767px) {
  .map__box {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    top: 0;
  }
}
.map__box-title {
  color: #fff;
}
.map__box a {
  color: #fff;
  font: normal normal normal 18px/28px Inter;
}
@media screen and (min-width: 1660px) {
  .map__box a {
    margin-right: 21px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .map__box a {
    display: block;
    margin-bottom: 21px;
  }
}
@media screen and (max-width: 767px) {
  .map__box a {
    margin-bottom: 21px;
  }
  .map__box a:last-of-type {
    margin-bottom: 0;
  }
}
.map__box address {
  margin-top: 32px;
  font: normal normal normal 18px/28px Inter;
}
.map__box address span {
  white-space: nowrap;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .map__box address {
    grid-column: 2/3;
    grid-row: 1/3;
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .map__box-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 21px 157px;
  }
}
@media screen and (max-width: 767px) {
  .map__box-container {
    display: flex;
    flex-direction: column;
  }
}

.catalog-page__wrap {
  max-width: 1190px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 56px 32px;
}
.catalog-page__item {
  width: 367px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .catalog-page__item {
    width: 292px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .catalog-page__item {
    width: 271px;
  }
}
.catalog-page__item:hover .portfolio__gallery-title:before {
  width: 100px;
}
.catalog-page__item:hover img {
  transform: scale(1.2);
}
.catalog-page__item_big {
  width: 500px;
}
.catalog-page__img {
  width: 100%;
  height: 262px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .catalog-page__img {
    height: 210px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .catalog-page__img {
    height: 195px;
  }
}
.catalog-page__text {
  max-width: 234px;
  color: #414141;
  font: normal normal normal 16px/28px Inter;
}

.catalog-detailed__info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .catalog-detailed__info {
    gap: 32px;
  }
}
@media screen and (max-width: 1279px) {
  .catalog-detailed__info {
    flex-direction: column;
  }
}
.catalog-detailed__img {
  width: 633px;
  height: 500px;
  margin-right: 165px;
}
.catalog-detailed__img:hover img {
  transform: scale(1.2);
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .catalog-detailed__img {
    width: 50%;
    margin-right: 0;
    height: auto;
  }
}
@media screen and (max-width: 1279px) {
  .catalog-detailed__img {
    width: 100%;
    height: auto;
    margin: 0 0 32px;
  }
}
.catalog-detailed__text {
  width: 367px;
  min-height: 500px;
  padding-bottom: 100px;
  font: normal normal normal 16px/28px Inter;
  color: #414141;
  position: relative;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .catalog-detailed__text {
    width: auto;
    max-width: 50%;
    min-height: unset;
  }
}
@media screen and (max-width: 1279px) {
  .catalog-detailed__text {
    min-height: unset;
    width: 100%;
  }
}
.catalog-detailed__button {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
}
@media screen and (max-width: 1279px) {
  .catalog-detailed__button {
    margin-top: 64px;
  }
}

.interior__wrap {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 500px);
}
@media screen and (max-width: 1279px) {
  .interior__wrap {
    grid-template-columns: 1fr;
  }
}
.interior__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  font: normal normal normal 16px/28px Inter;
  color: #414141;
}
@media screen and (max-width: 1279px) {
  .interior__item {
    font-size: 14px;
    line-height: 24px;
  }
}
.interior__item:hover .interior__item-title:before {
  width: 100px;
}
.interior__item:hover img {
  transform: scale(1.2);
}
.interior__item:first-of-type {
  grid-column: 1/3;
}
@media screen and (max-width: 1279px) {
  .interior__item:first-of-type {
    grid-column: unset;
  }
}
.interior__item-img {
  width: 500px;
  height: 357px;
}
@media screen and (max-width: 1279px) {
  .interior__item-img {
    width: 100%;
    height: unset;
    aspect-ratio: 326/257;
  }
}
.interior__item-box {
  padding: 32px;
  max-width: 431px;
}
@media screen and (max-width: 1279px) {
  .interior__item-box {
    padding: 32px 0 0 32px;
  }
}
.interior__item-title {
  display: block;
  color: #008066;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  position: relative;
}
.interior__item-title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  background-color: #008066;
  transition: width 0.3s ease-in-out;
  left: 0;
  top: -10px;
}

.interior-detailed__item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  margin: 0 30px;
}
@media screen and (max-width: 1279px) {
  .interior-detailed__item {
    flex-direction: column;
  }
}
.interior-detailed__text {
  font: normal normal normal 16px/28px Inter;
  width: 234px;
  max-height: 784px;
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .interior-detailed__text {
    width: 184px;
    max-height: unset;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .interior-detailed__text {
    width: 170px;
    max-height: unset;
  }
}
@media screen and (max-width: 1279px) {
  .interior-detailed__text {
    order: 1;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }
}
.interior-detailed__year {
  padding: 102px 0;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .interior-detailed__year {
    padding: 70px 0;
  }
}
@media screen and (max-width: 1279px) {
  .interior-detailed__year {
    padding: 0 10px 21px 10px;
    margin-left: auto;
  }
}
.interior-detailed__img {
  cursor: pointer;
}
@media screen and (max-width: 1279px) {
  .interior-detailed__img {
    order: 0;
    width: calc(100% + 32px);
    height: 257px;
    position: relative;
    left: -16px;
  }
}
.interior-detailed__img:hover img {
  transform: scale(1.2);
}
@media screen and (max-width: 1279px) {
  .interior-detailed__img:hover img {
    transform: unset;
  }
}
.interior-detailed__slider-wrap {
  position: relative;
}
.interior-detailed__arrow-box {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 186px;
  font: normal normal normal 22px/28px Inter;
  color: #414141;
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .interior-detailed__arrow-box {
    width: 170px;
  }
}
@media screen and (max-width: 1279px) {
  .interior-detailed__arrow-box {
    left: 10px;
    bottom: unset;
    top: 282px;
  }
  .interior-detailed__arrow-box .default-arrow {
    display: none;
  }
}
.interior-detailed__slider {
  margin: 0 -30px;
}

.about__wrap {
  width: 1130px;
  font: normal normal normal 16px/28px Inter;
  position: relative;
  color: #414141;
}
@media screen and (max-width: 1659px) {
  .about__wrap {
    width: 100%;
  }
}
.about__aside {
  width: 200px;
  margin-left: auto;
  font: normal normal normal 14px/24px Inter;
  color: #414141;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .about__aside {
    margin-left: unset;
  }
}
.about__subtitle {
  font-size: 48px;
  line-height: 56px;
  padding-bottom: 37px;
  color: #2F3A41;
  width: 584px;
  font-weight: 500;
}
.about__img:hover img {
  transform: scale(1.2);
}
.about__img_big {
  width: 899px;
}
.about__img-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
}
.about__float {
  position: relative;
  left: -135px;
}
.about__blocktitle {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  color: #2F3A41;
  padding-bottom: 18px;
}
.about__list-title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 18px;
}
.about__longtext p {
  padding-bottom: 1.5em;
}

.not-found__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 1660px) {
  .not-found__wrap {
    gap: 94px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .not-found__wrap {
    gap: 113px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .not-found__wrap {
    gap: 104px;
  }
}
@media screen and (min-width: 1660px) {
  .not-found__info {
    max-width: 488px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .not-found__info {
    max-width: 400px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .not-found__info {
    max-width: 372px;
  }
}
.not-found__title {
  font-weight: 800;
  color: #2F3A41;
}
@media screen and (min-width: 1660px) {
  .not-found__title {
    font-size: 210px;
    line-height: 256px;
    padding-bottom: 31px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .not-found__title {
    font-size: 170px;
    line-height: 208px;
    padding-bottom: 41px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .not-found__title {
    font-size: 160px;
    line-height: 195px;
    padding-bottom: 44px;
  }
}
@media screen and (max-width: 1270px) {
  .not-found__title {
    font-size: 130px;
    line-height: 159px;
    padding-bottom: 72px;
  }
}
.not-found__text {
  font: normal normal normal 16px/28px Inter;
  color: #414141;
  padding-bottom: 54px;
}
@media screen and (max-width: 1270px) {
  .not-found__text {
    font-size: 14px;
  }
}
.not-found__img img {
  width: 100%;
}
@media screen and (min-width: 1660px) {
  .not-found__img {
    max-width: 663px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .not-found__img {
    max-width: 603px;
  }
}
@media screen and (max-width: 1270px) {
  .not-found__img {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: -5vh;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(24, 38, 56, 0.9);
  display: none;
  overflow-y: auto;
}
.overlay.active {
  display: block;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
@media screen and (max-width: 767px) {
  .modal {
    top: 0;
    left: 0;
    right: 0;
    transform: unset;
  }
}
.modal.active {
  display: block;
}
.modal__close {
  position: absolute;
  right: 50px;
  top: 50px;
  cursor: pointer;
  background-color: rgb(24, 38, 56);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}
.modal__close:before, .modal__close:after {
  content: "";
  background-color: #fff;
  display: block;
  width: 25px;
  height: 3px;
  transform-origin: center center;
  position: absolute;
}
.modal__close:before {
  transform: rotate(45deg);
}
.modal__close:after {
  transform: rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .modal__close {
    width: 32px;
    height: 32px;
    top: 17px;
    right: 17px;
  }
}
.modal__arrow {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  width: 43px;
  height: 43px;
  top: calc(50% - 20px);
}
.modal__arrow_left {
  transform: rotate(135deg);
  left: 10%;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .modal__arrow_left {
    left: 50%;
    transform: translateX(-42vw) rotate(135deg);
  }
}
.modal__arrow_right {
  transform: rotate(-45deg);
  right: 10%;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .modal__arrow_right {
    right: 50%;
    transform: translateX(42vw) rotate(-45deg);
  }
}
.modal__arrow .arrow {
  border: solid #008066;
  border-width: 0 3px 3px 0;
  padding: 20px;
  display: block;
  transition: border-color 0.3s ease;
}
.modal__arrow .arrow:hover {
  border-color: #0A5546;
}
.modal-slider__item {
  max-width: 1043px;
  margin: auto;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .modal-slider__item {
    max-height: 90vh;
  }
}
.modal-slider__arrow-box {
  position: absolute;
  bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  color: #fff;
  font: normal normal normal 22px/1.4 Inter;
  text-align: center;
  padding: 6px 14px;
  letter-spacing: 2.2px;
  background-color: rgb(24, 38, 56);
  border-radius: 90px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .modal-slider__arrow-box {
    padding: 5px 10px;
    font-size: 18px;
  }
}

.modal-callback, .modal-header, .modal-msg {
  background-color: #ECF0F5;
  padding: 56px 133px 66px;
  width: 100%;
  max-width: 1065px;
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .modal-callback, .modal-header, .modal-msg {
    padding: 40px 100px 50px;
    max-width: 80vw;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .modal-callback, .modal-header, .modal-msg {
    padding: 44px 32px;
    max-width: calc(100% - 40px);
  }
}
@media screen and (max-width: 767px) {
  .modal-callback, .modal-header, .modal-msg {
    padding: 89px 17px 57px;
    max-width: 100%;
  }
}
.modal-callback__title, .modal-header__title, .modal-msg__title {
  color: rgb(47, 58, 65);
  font-size: 48px;
  line-height: 117%;
  padding-bottom: 37px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .modal-callback__title, .modal-header__title, .modal-msg__title {
    font-size: 32px;
    font-weight: 600;
  }
}
.modal-callback__desc, .modal-header__desc, .modal-msg__desc {
  max-width: 549px;
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 59px;
}
@media screen and (max-width: 767px) {
  .modal-callback__desc, .modal-header__desc, .modal-msg__desc {
    font-size: 20px;
    padding-bottom: 50px;
  }
}
.modal-callback__row, .modal-header__row, .modal-msg__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding-bottom: 44px;
}
.modal-callback__row:last-of-type, .modal-header__row:last-of-type, .modal-msg__row:last-of-type {
  padding-bottom: 88px;
}
@media screen and (min-width: 768px) and (max-width: 1659px) {
  .modal-callback__row, .modal-header__row, .modal-msg__row {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .modal-callback__row, .modal-header__row, .modal-msg__row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.modal-callback__row-submit, .modal-header__row-submit, .modal-msg__row-submit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
}
@media screen and (max-width: 1023px) {
  .modal-callback__row-submit, .modal-header__row-submit, .modal-msg__row-submit {
    gap: 40px;
  }
}
@media screen and (max-width: 767px) {
  .modal-callback__row-submit, .modal-header__row-submit, .modal-msg__row-submit {
    flex-direction: column;
    align-items: flex-start;
  }
}
.modal-callback__field, .modal-header__field, .modal-msg__field {
  width: 100%;
}
.modal-callback__field a, .modal-header__field a, .modal-msg__field a {
  color: #008066;
  text-decoration: underline;
}
.modal-callback__checkbox, .modal-header__checkbox, .modal-msg__checkbox {
  position: relative;
  font-size: 14px;
  color: #898989;
  width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.modal-callback__checkbox.error, .modal-header__checkbox.error, .modal-msg__checkbox.error {
  width: unset;
}
.modal-callback__checkbox.error input::before, .modal-header__checkbox.error input::before, .modal-msg__checkbox.error input::before {
  border-color: #f01e2c;
}
.modal-callback__checkbox input, .modal-header__checkbox input, .modal-msg__checkbox input {
  width: 0;
  appearance: none;
  margin-right: 40px;
}
.modal-callback__checkbox input::before, .modal-header__checkbox input::before, .modal-msg__checkbox input::before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  border: 1px solid #008066;
}
.modal-callback__checkbox input::after, .modal-header__checkbox input::after, .modal-msg__checkbox input::after {
  content: "";
  width: 13px;
  height: 7px;
  position: absolute;
  top: 7px;
  left: 8px;
  border-bottom: 3px solid #008066;
  border-left: 3px solid #008066;
  transform: rotate(-45deg);
  display: none;
}
.modal-callback__checkbox input:checked::after, .modal-header__checkbox input:checked::after, .modal-msg__checkbox input:checked::after {
  display: block;
}
.modal-callback__label, .modal-header__label, .modal-msg__label {
  display: inline-block;
  font: normal normal normal 14px/24px Inter;
  color: rgb(65, 65, 65);
  padding-bottom: 16px;
}
.modal-callback__input, .modal-header__input, .modal-msg__input {
  border: 1px solid rgb(150, 150, 150);
  height: 48px;
  background-color: transparent;
  width: 100%;
  padding: 5px 10px;
}
@media screen and (max-width: 767px) {
  .modal-callback__input, .modal-header__input, .modal-msg__input {
    height: 43px;
  }
}
.modal-callback__textarea, .modal-header__textarea, .modal-msg__textarea {
  border: 1px solid rgb(150, 150, 150);
  height: 100px;
  background-color: transparent;
  width: 100%;
  padding: 5px 10px;
}
.modal-callback__submit, .modal-header__submit, .modal-msg__submit {
  padding: 20px 66px;
  display: block;
  margin-left: auto;
  border: none;
}
@media screen and (max-width: 767px) {
  .modal-callback__submit, .modal-header__submit, .modal-msg__submit {
    margin: 0;
    width: 100%;
  }
}

.modal-msg__desc {
  max-width: 759px;
}
.modal-msg__check {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .modal-msg__check {
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
}
.modal-msg__check span {
  font-family: Inter, sans-serif;
  color: #898989;
  max-width: 500px;
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .modal-msg__check span {
    max-width: 467px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .modal-msg__check span {
    max-width: 374px;
  }
}
@media screen and (max-width: 767px) {
  .modal-msg__check span {
    max-width: 100%;
  }
}

.page-text h2 {
  padding-bottom: 37px;
}
.page-text h3 {
  padding-bottom: 18px;
}
.page-text h4 {
  padding-bottom: 18px;
}
@media screen and (min-width: 1280px) {
  .page-text h2, .page-text h3, .page-text h4 {
    max-width: 584px;
  }
}
@media screen and (max-width: 1279px) {
  .page-text h2, .page-text h3, .page-text h4 {
    max-width: 326px;
  }
}
.page-text table {
  width: 100%;
  border-spacing: 32px 0;
  position: relative;
  left: -159px;
  margin-bottom: 88px;
}
@media screen and (max-width: 1279px) {
  .page-text table {
    position: unset;
    margin-bottom: 44px;
  }
}
.page-text table th, .page-text table td {
  padding: 32px 16px 23px;
  color: #414141;
}
.page-text table th {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  border-bottom: 2px solid #0A5546;
}
@media screen and (max-width: 1279px) {
  .page-text table th {
    line-height: 24px;
  }
}
.page-text table td {
  border-bottom: 1px solid rgba(0, 128, 102, 0.6);
  font: normal normal normal 16px/28px Inter;
}
@media screen and (max-width: 1279px) {
  .page-text .table-wrap {
    overflow-x: auto;
  }
}
.page-text .table-wrap::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}
.page-text ul, .page-text ol {
  color: #414141;
  font: normal normal normal 16px/28px Inter;
  list-style-position: outside;
}
@media screen and (max-width: 1279px) {
  .page-text ul, .page-text ol {
    list-style-position: inside;
    font: normal normal normal 14px/24px Inter;
  }
}
.page-text li {
  padding-bottom: 1em;
}
.page-text li::marker {
  color: #008066;
  font-size: 16px;
  line-height: 28px;
}
.page-text p {
  padding-bottom: 1em;
}
.page-text p:last-of-type {
  padding-bottom: 0;
}
@media screen and (min-width: 1660px) {
  .page-text ul, .page-text ol, .page-text p {
    width: 766px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1659px) {
  .page-text ul, .page-text ol, .page-text p {
    width: 616px;
  }
}
.page-text .block {
  padding-bottom: 88px;
}
@media screen and (max-width: 1659px) {
  .page-text .block {
    padding-bottom: 60px;
  }
}

.contacts .container-outer {
  position: unset;
}
@media screen and (min-width: 1660px) {
  .contacts .map {
    height: 980px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1659px) {
  .contacts .map {
    height: 768px;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .contacts .map {
    height: 800px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map {
    height: 1024px;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map {
    height: 900px;
  }
}
.contacts .map__box {
  top: 122px;
  padding: 40px 32px;
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .contacts .map__box {
    width: 365px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map__box {
    position: absolute;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 40px 40px 0;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map__box {
    position: absolute;
    left: 0;
    right: 0;
    top: unset;
    bottom: 0;
    width: 100%;
    padding: 36px 17px 0;
  }
}
.contacts .map__box-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map__box-container {
    border-bottom: 2px solid #008066;
    display: grid;
    grid-gap: 0 59px;
    grid-template-columns: 1fr 284px;
    padding: 0 0 51px;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map__box-container {
    border-bottom: 2px solid #008066;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0 51px;
  }
}
.contacts .map__box a {
  font-size: 28px;
  margin-bottom: 24px;
}
.contacts .map__box a:last-of-type {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map__box a {
    grid-column: 1/2;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map__box a {
    font-size: 14px;
    margin-bottom: 21px;
  }
}
.contacts .map__box-button {
  font-size: 18px;
  margin-top: 62px;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map__box-button {
    margin-top: 0;
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map__box-button {
    width: 100%;
    justify-content: center;
    margin-top: 45px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .contacts .map__box address {
    margin-top: 32px;
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
@media screen and (max-width: 767px) {
  .contacts .map__box address {
    font-size: 14px;
  }
}